export default [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "首页",
      login: true,
    },
    component: () => import("@/views/Home.vue"),
  },
];
