export default [
  {
    path: "/order-management",
    name: "order_management",
    redirect: {
      name: "order_list",
    },
    meta: {
      title: "课程订单",
      login: true,
    },
    component: () => import("@/views/orderManagement/index.vue"),
    children: [
      {
        path: "/order-list",
        name: "order_list",
        meta: {
          title: "订单管理",
          login: true,
        },
        component: () => import("@/views/orderManagement/shopping-order.vue"),
      },
      {
        path: "/refund-list",
        name: "refund_list",
        meta: {
          title: "退款审核",
          login: true,
        },
        component: () => import("@/views/orderManagement/refund-audit.vue"),
      },
    ],
  },
];
