import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    menuIndex: "",
    tagArr: [],
    zn: true,
    lang: "zh",
    type: "",
    phone: 0,
  },
  mutations: {
    changeMenuIndex(state, data) {
      state.menuIndex = data;
    },
    changeTagArr(state, data) {
      state.tagArr = data;
    },
    changeLang(state, data) {
      state.zn = data;
    },
    changZn(state, data) {
      state.lang = data;
    },
    changeType(state, data) {
      state.type = data;
    },
    changePhone(state, data) {
      state.phone = data;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});
