<template>
  <router-view />
</template>

<script>
export default {
  created() {
    this.bus.$on("changeLang", (val) => {
      document.title = val ? this.$route.meta.title : this.$route.name;
    });
  },
  mounted() {},
};
</script>

<style lang="scss">
body {
  margin: 0;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .main {
      min-width: 1000px;
      margin-top: 80px;
      margin-left: 64px;
      padding: 0;

      .content {
        padding: 20px;
        padding-top: 65px;

        .form-item {
          width: auto;

          .el-input {
            width: 150px;
          }
        }

        .form-submit {
          box-sizing: border-box;
        }

        .table-content {
          position: relative;
          padding: 30px;

          .table-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
          }

          .table-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .delete-btn {
              margin: 20px 0;
            }
          }
        }

        // .el-select {
        //   width: 100%;
        // }

        .video {
          width: 100%;
        }
      }
    }
  }
}
</style>
