import { createRouter, createWebHashHistory } from "vue-router";
import Home from "./home";
import store from "../store";
import adminOperation from "./admin-management";
import shoppingManagement from "./order-management";
import operateManagement from "./operate-management";
import contentManagement from "./content-management";
import systemManagement from "./system-management";
import userCenter from "./user-center";

const routes = [
  ...Home,
  ...adminOperation,
  ...userCenter,
  ...contentManagement,
  ...operateManagement,
  ...shoppingManagement,
  ...systemManagement,
  {
    path: "/:pathMatch(.*)*",
    name: "noFound",
    redirect: {
      name: "Home",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = store.state.zn ? to.meta.title : to.name;
  }

  // 权限
  let Arr = JSON.parse(localStorage.getItem("menuList"));
  try {
    if (Arr) {
      Arr.forEach((item) => {
        if (item.children && item.children.length > 0) {
          for (let index = 0; index < item.children.length; index++) {
            let menu_name = item.children[index].function;
            let cld = item.children[index].children;
            if (menu_name == to.path) {
              if (cld && cld.length > 0) {
                let type = cld.map((item) => item.menu_name).toString();
                store.commit("changeType", type);
              }
              throw new Error("BreakFor");
            } else {
              store.commit("changeType", "");
            }
          }
        }
      });
    }
  } catch (error) {
    if (error.message != "BreakFor") {
      throw error;
    }
  }

  // 获取token
  const token = localStorage.getItem("token");
  // 初始未登录
  const isLogin = !!token;
  // 需要登陆
  if (to.matched.some((item) => item.meta.login)) {
    // 如果登陆，直接进入
    if (isLogin) {
      // token不合法（过期）
      if (!token) {
        // 跳转login
        next({ name: "Login" });
        return;
      }
      if (to.name === "Login") {
        next({ name: "Home" });
      }
      // 已登录，直接进入
      next();
      return;
    }
    // 未登录，进入login，直接进入
    if (!isLogin && to.name === "Login") {
      next();
    }
    // 未登录，进入的不是login，跳到login
    if (!isLogin && to.name !== "Login") {
      next({ name: "Login" });
    }
  } else {
    // 不需要登陆，直接进入
    next();
  }
});

export default router;
