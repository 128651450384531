export default [
  {
    path: "/users-center",
    name: "user_center",
    redirect: {
      name: "user_list",
    },
    meta: {
      title: "用户中心",
      login: true,
    },
    component: () => import("@/views/userCenter/index.vue"),
    children: [
      {
        path: "/users-list",
        name: "user_list",
        meta: {
          title: "用户管理",
          login: true,
        },
        component: () => import("@/views/userCenter/user-list.vue"),
      },
      {
        path: "/users-detail/:id",
        name: "user_detail",
        meta: {
          title: "用户详情",
          login: true,
        },
        component: () => import("@/views/userCenter/user-detail.vue"),
      },
      {
        path: "/institu-list",
        name: "institu_list",
        meta: {
          title: "机构管理",
          login: true,
        },
        component: () => import("@/views/userCenter/institu-list.vue"),
      },
      {
        path: "/tag-list",
        name: "tag_list",
        meta: {
          title: "标签管理",
          login: true,
        },
        component: () => import("@/views/userCenter/tag-list.vue"),
      },
    ],
  },
];
