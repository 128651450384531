export default [
  {
    path: "/operate-management",
    name: "operate_management",
    redirect: {
      name: "activity_list",
    },
    meta: {
      title: "运营中心",
      login: true,
    },
    component: () => import("@/views/operateManagement/index.vue"),
    children: [
      {
        path: "/project-list",
        name: "project_list",
        meta: {
          title: "专题管理",
          login: true,
        },
        component: () => import("@/views/operateManagement/project-list.vue"),
      },
      {
        path: "/feedback-list",
        name: "feedback_list",
        meta: {
          title: "反馈管理",
          login: true,
        },
        component: () => import("@/views/operateManagement/feedback-list.vue"),
      },
      {
        path: "/advert-list",
        name: "advert_list",
        meta: {
          title: "广告管理",
          login: true,
        },
        component: () => import("@/views/operateManagement/advert-list.vue"),
      },
      {
        path: "/course",
        name: "uservideo_class",
        meta: {
          title: "课程模板",
          login: true,
        },
        component: () => import("@/views/operateManagement/course.vue"),
      },
      {
        path: "/activity",
        name: "uservideo_activity",
        meta: {
          title: "活动模板",
          login: true,
        },
        component: () => import("@/views/operateManagement/activity.vue"),
      },
      {
        path: "/activity-list",
        name: "activity_list",
        meta: {
          title: "完返现课",
          login: true,
        },
        component: () => import("@/views/operateManagement/activity-list.vue"),
      },
      {
        path: "/expression",
        name: "uservideo_expressions",
        meta: {
          title: "Expression",
          login: true,
        },
        component: () => import("@/views/operateManagement/expression.vue"),
      },
    ],
  },
];
