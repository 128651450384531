export default [
  {
    path: "/system-management",
    name: "system_management",
    redirect: {
      name: "system_user",
    },
    meta: {
      title: "系统管理",
      login: true,
    },
    component: () => import("@/views/systemManagement/index.vue"),
    children: [
      {
        path: "/system-home",
        name: "system_index",
        meta: {
          title: "系统管理首页",
          login: true,
        },
        component: () => import("@/views/systemManagement/system-home.vue"),
      },
      {
        path: "/menu-list",
        name: "menu_list",
        meta: {
          title: "菜单管理",
          login: true,
        },
        component: () => import("@/views/systemManagement/menu-list.vue"),
      },
      {
        path: "/user-management",
        name: "system_user",
        meta: {
          title: "员工管理",
          login: true,
        },
        component: () => import("@/views/systemManagement/user-management.vue"),
      },
      {
        path: "/department-list",
        name: "department_list",
        meta: {
          title: "部门管理",
          login: true,
        },
        component: () => import("@/views/systemManagement/department-list.vue"),
      },
      {
        path: "/group-list",
        name: "group_list",
        meta: {
          title: "小组管理",
          login: true,
        },
        component: () => import("@/views/systemManagement/group-list.vue"),
      },
      {
        path: "/client-version",
        name: "system_clent",
        meta: {
          title: "客户端版本管理",
          login: true,
        },
        component: () => import("@/views/systemManagement/client-version.vue"),
      },
      {
        path: "/system-log",
        name: "system_log",
        meta: {
          title: "系统日志",
          login: true,
        },
        component: () => import("@/views/systemManagement/system-log.vue"),
      },
    ],
  },
];
