export default [
  {
    path: "/content-management",
    name: "content_management",
    redirect: {
      name: "follow_list",
    },
    meta: {
      title: "内容中心",
      login: true,
    },
    component: () => import("@/views/contentManagement/index.vue"),
    children: [
      {
        path: "/follow-list",
        name: "follow_list",
        meta: {
          title: "跟读小程序",
          login: true,
          keepAlive: true,
        },
        component: () =>
          import("@/views/contentManagement/follow-management.vue"),
      },
      {
        path: "/follow-add/:id",
        name: "follow_add",
        meta: {
          title: "创建课程信息",
          login: true,
        },
        component: () => import("@/views/contentManagement/follow-add.vue"),
      },
      {
        path: "/follow-edior/:id",
        name: "follow_edior",
        meta: {
          title: "编辑跟读任务",
          login: true,
        },
        component: () => import("@/views/contentManagement/follow-edior.vue"),
      },
      {
        path: "/follow-look/:id/:type",
        name: "follow_look",
        meta: {
          title: "查看跟读任务",
          login: true,
        },
        component: () => import("@/views/contentManagement/follow-look.vue"),
      },
    ],
  },
];
