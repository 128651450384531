import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/lib/theme-chalk/index.css";
import "element-plus/packages/theme-chalk/src/base.scss";
import "dayjs/locale/zh-cn";
import ZhLocale from "element-plus/lib/locale/lang/zh-cn"; // 中文
import EhLocale from "element-plus/lib/locale/lang/en"; // 英文
import I18n from "./assets/js/lang";
import VueCookies from "vue-cookies";
import audio from "vue-mobile-audio";
import Sortable from "sortablejs";
import dayjs from "dayjs";
import Bus from "./assets/js/bus";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.bus = Bus;
app.config.globalProperties.sort = Sortable;

let locale = "";
dayjs().format();

if (I18n.global.fallbackLocale == "zh") {
  locale = ZhLocale;
} else {
  locale = EhLocale;
}

app.use(ElementPlus, { locale });
app.use(I18n);
app.use(audio);
app.use(store);
app.use(router);
app.mount("#app");
