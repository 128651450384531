export default [
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
      login: true,
    },
    component: () => import("@/views/admin/login.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: {
      title: "重置密码密码",
      login: false,
    },
    component: () => import("@/views/admin/forgot-password.vue"),
  },
  {
    path: "/amend-password",
    name: "amendPassword",
    meta: {
      title: "修改密码",
      login: true,
    },
    component: () => import("@/views/admin/amend-password.vue"),
  },
];
